import {
    ethers
} from "ethers";

import store from "@/store";


let ContractAddress;
let Abi;

async function start(){
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  let res1= await signer.getChainId();;
    let address='address'
    if (res1=='1337') {
      address='address'
    }else{
        address='address_bsc'
    }

   
    const { default: Contract } = await import(`@/wallet/${address}/LogicRole.logicRoleAddress`);
    ContractAddress = Contract.address;
    Abi = Contract.abi;


};



export async function createRoleApi(roleNumber,name) {
      await start();
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        console.log("公共方法",roleNumber)
        let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
        console.log("公共方法1",name)
        let res = await myContract.createRole(roleNumber, name);
        store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)+1);
        if(res.wait()){
          await res.wait();
         
        }
        if(res){
            
            return res;
        }else{
          return false
        }
        
       //   
}

export async function getRoleList() {
  console.log("封装方法调用getRoleList0")
  await start();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    console.log("封装方法调用getRoleList")
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
    console.log("封装方法调用getRoleList1")
    let res = await myContract.getRoleList();
    
    if(res){
        
        return res;
    }else{
      return false
    }
      
}
export async function getRoleArms(roleid,addr) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
    
    let address= await signer.getAddress();
    if(addr){
      address= addr;
    }
   
    let res = await myContract.getRoleArms(roleid,address);
    
    if(res){
        
        return res;
    }else{
      return false
    }
}
export async function getRole(roleid) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
    
 
    let res = await myContract.getRole(roleid);
    
    if(res){
        
        return res;
    }else{
      return false
    }
}

export async function rolePushArmsGem(roleid,armsIds,gemIds) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
    console.log("角色升级传入id",roleid);
    console.log("角色升级传入武器",armsIds);
    console.log("角色升级传入宝石",gemIds);
    let res = await myContract.rolePushArmsGem(roleid,armsIds,gemIds);
    store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)+1);
    if(res.wait()){
      await res.wait();
     
    }
    if(res){
        
        return res;
    }else{
      return false
    }
}




