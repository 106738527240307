import { Wallet } from "ethers";

export const state = {
  isWalletConnect:true,
  isTokenAuth:true,
  isArmNftAuth:false,
  isRoleNftAuth:false,
  isGemNftAuth:false,
  isMarketNftAuth:false,
  isMarketArmNftAuth:false,
  isMarketGemNftAuth:false,
  isLogicTransferNftAuth:false,
  isArmTransferNftAuth:false,
  isGemTransferNftAuth:false,
  erc20Address:'',
  //
  xyjTokenBalance:0,
  isArmTokenAuth:false,
  isGemTokenAuth:false,
  getIsBossTokenAuth:false,
  isMarketTokenAuth:false,
  isTransferTokenAuth:false,
  //top meun chose
  choseRoleId:0,
  //roleList
  roleListData:'',
  //总战力
  totalMinePower:0,
  level:0,

  //新版授权
  isTokenLogicStorage:false,
  isArmNftLogicStorage:false,
  isGemNftLogicStorage:false,
  isRoleNftLogicStorage:false,

  //链id
  ChainId:0,
  //等待的交易数量
  waitCount:0,
  //调起钱包状态
  walletWait:false,
  //销毁价格
  destroyPrice:0
}