<template>
	<div class="">
		<div class="grid">
		
		<template v-if="getChainId == 1337 || getChainId == 97  ">

		</template>
			<template v-else>
				<div class="col-12" >
					<div class="card border-green-500 border-left-3 border-noround mb-0">
						<div class="mb-0 flex">
							<i class="pi pi-exclamation-triangle mt-1 font-bold text-green-500 mr-2 "></i>
							<div>
								<p class="text-green-500 mb-0">
									安全警告:
								</p>
								
								<p class="text-sm">
									当前链接的钱包网络不正确，请正确链接。
									<a style="text-decoration:underline" href="https://medium.com/spartanprotocol/how-to-connect-metamask-to-bsc-testnet-7d89c111ab2" target="_blank">
									如何链接正确的网络？</a>
								</p>
							</div>
							
							
						</div>

					</div>
				</div>
		</template>
			<div class="col-12">
				<div class="card border-theme-color border-left-3 border-noround">
					<div class="mb-0  flex">
						<i class="iconfont icon-qizhi mt-1 font-bold mr-2 text-theme"></i>
						<div class="">
							<p class="mb-0 text-theme">
								欢迎来到西游记世界
							</p>
							<p class="text-sm">
								西游记是一款神话游戏，在游戏中可以赚取NFT。游戏主要通过抽取人物角色，匹配相应武器和技能宝石去不同副本地图打妖怪，获取奖励。游戏尽可能满足玩家对西游记的故事体验，并在此过程中赚取一些利润奖励。
							</p>
						</div>
					</div>


				</div>
			</div>
		</div>
		<div class="grid mb-4">
			<div class="col-12">
				<authList/>
			</div>
		</div>
		<div class="grid">
			<div class="col-12 lg:col-4 xl:col-4">
				<div class="card mb-0 text-center imgCard text-left">
					<img src="@/assets/img/monkey.jpg" />
					
				</div>
			</div>
			<div class="col-12 lg:col-4 xl:col-4">
				<div class="card mb-0 text-center imgCard text-left">
					<img src="@/assets/img/pig.jpg" />
					
				</div>
			</div>
			<div class="col-12 lg:col-4 xl:col-4">
				<div class="card mb-0 text-center imgCard text-left">
					<img src="@/assets/img/shazeng.jpg" />
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ProductService from '../service/ProductService';
	import authList from "../components/AuthList/authList.vue";
	import { defineComponent,ref,store,watch} from 'vue';
	import { useStore } from 'vuex';
	export default defineComponent({
		setup() {
			 const store = useStore();
			let getChainId=ref(store.getters.getChainId);
			watch(()=>store.getters.getChainId,()=>{
				
				getChainId.value=store.getters.getChainId;
			})

			return {
				getChainId
			}
		},
		 components: {
			authList
		},
		data() {
			return {
				products: null,
				lineData: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					datasets: [{
							label: 'Revenue',
							data: [65, 59, 80, 81, 56, 55, 40],
							fill: false,
							backgroundColor: '#2f4860',
							borderColor: '#2f4860',
							tension: 0.4
						},
						{
							label: 'Sales',
							data: [28, 48, 40, 19, 86, 27, 90],
							fill: false,
							backgroundColor: '#00bb7e',
							borderColor: '#00bb7e',
							tension: 0.4
						}
					]
				},
				items: [{
						label: 'Add New',
						icon: 'pi pi-fw pi-plus'
					},
					{
						label: 'Remove',
						icon: 'pi pi-fw pi-minus'
					}
				]
			}
		},
		productService: null,
		created() {
			this.productService = new ProductService();
		},
		mounted() {
			//this.productService.getProductsSmall().then(data => this.products = data);
		},
		methods: {
			formatCurrency(value) {
				return value.toLocaleString('en-US', {
					style: 'currency',
					currency: 'USD'
				});
			}
		}
	})
</script>
<style lang="scss" scoped>
.imgCard{
	height: 550px;
	padding: 0;
	img{
		height: 100%;
		margin: 0;
	}
	&:hover{
		border:1px solid #17212f;
	}
}
</style>