const mutations = {
    UpdateIsWalletConnect(state,isauth){
        console.log("mutation更新UpdateIsWalletConnect",isauth)
        state.isWalletConnect= isauth;
    },
    UpdateIsTokenAuth(state,isTokenAuth){
        console.log("mutation更新TOKEN",isTokenAuth)
       // localStorage.setItem("isTokenAuth", isTokenAuth);
        state.isTokenAuth= isTokenAuth;
    },
    UpdateIsArmNftAuth(state,isauth){
      
        state.isArmNftAuth= isauth;
    },
    UpdateIsRoleNftAuth(state,isauth){
       
        state.isRoleNftAuth= isauth;
    },
    UpdateIsMarketNftAuth(state,isauth){
       
        state.isMarketNftAuth= isauth;
    },
    UpdateIsMarketArmNftAuth(state,isauth){
       
        state.isMarketArmNftAuth= isauth;
    },
    UpdateIsMarketGemNftAuth(state,isauth){
       
        state.isMarketGemNftAuth= isauth;
    },
    UpdateIsGemNftAuth(state,isauth){
      
        state.isGemNftAuth= isauth;
    },
    UpdateIsLogicTransferNftAuth(state,isauth){
      
        state.isLogicTransferNftAuth= isauth;
    },
    UpdateIsArmTransferNftAuth(state,auth){
        state.isArmTransferNftAuth=auth;
    },
    UpdateIsGemTransferNftAuth(state,auth){
        state.isGemTransferNftAuth=auth;
    },
    UpdateErc20Address(state,address){
     
        state.erc20Address= address;
    },
    UpdateXyjTokenBalance(state,count){
         state.xyjTokenBalance=count;
      },
    UpdateIsArmTokenAuth(state,isauth){
      
        state.isArmTokenAuth=isauth;
        
    },
    UpdateIsGemTokenAuth(state,isauth){
       
        state.isGemTokenAuth=isauth;
        
    },
    UpdateIsBossTokenAuth(state,isauth){
       
        state.isBossTokenAuth=isauth;
        
    },
    UpdateIsMarketTokenAuth(state,isauth){
       
        state.isMarketTokenAuth= isauth;
    },
    UpdateIsTransferTokenAuth(state,isauth){
       
        state.isTransferTokenAuth= isauth;
    },
    //
    UpdateChoseRoleId(state,roleId){
        state.choseRoleId=roleId
    },
    UpdateRoleListData(state,rolelist){
        state.roleListData=rolelist
    },
    UpdateTotalMinePower(state,power){
        state.totalMinePower=power
    },
    //新版授权
    UpdateIsTokenLogicStorage(state,auth){
        state.isTokenLogicStorage=auth
    },
    UpdateIsArmNftLogicStorage(state,auth){
        state.isArmNftLogicStorage=auth
    },
    UpdateIsGemNftLogicStorage(state,auth){
        state.isGemNftLogicStorage=auth
    },
    UpdateIsRoleNftLogicStorage(state,auth){
        state.isRoleNftLogicStorage=auth
    },
    //更新链id
    UpdateChainId(state,id){
        state.ChainId=id;
    },
    //更新交易数量
    UpdateWaitCount(state,num){
        state.waitCount=num;
    },
    //更新调起状态
    UpdateWalletWait(state,isShow){
        state.walletWait=isShow;
    },
    UpdateDestroyPrice(state,price){
        state.destroyPrice=price;
    },
}
export default mutations