import {
    ethers
} from "ethers";


import store from "@/store";



let role_ContractAddress;
let arm_ContractAddress;
let gem_ContractAddress;
let token_ContractAddres;
let logicStorageAddress;

let Abi;
let tokenAbi;
let storageAbi;



export async function start() {
   
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let res1= await signer.getChainId();
    let net_address='address'
      if (res1=='1337') {
        net_address='address'
      }else{
        net_address='address_bsc'
      }

   
    const {
        default: token_Contract
    } = await import(`@/wallet/${net_address}/ERC20Token.erc20TokenAddress.json`);
   
    const {
        default: role_Contract
    } = await import(`@/wallet/${net_address}/Nft.roleAddress`);
 
    const {
        default: arm_Contract
    } = await import(`@/wallet/${net_address}/Nft.armsAddress`);
  
    const {
        default: gem_Contract
    } = await import(`@/wallet/${net_address}/Nft.gemAddress`);
   
    const {
        default: logicStorage_Contract
    } =await import(`@/wallet/${net_address}/LogicStorage.logicStorageAddress`);
    
    role_ContractAddress = role_Contract.address
    arm_ContractAddress = arm_Contract.address;
    gem_ContractAddress = gem_Contract.address;
    token_ContractAddres = token_Contract.address;
    logicStorageAddress = logicStorage_Contract.address;
    Abi = role_Contract.abi;
    tokenAbi = token_Contract.abi;
    storageAbi = logicStorage_Contract.abi;

};
export async function approve() {
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    let mytokenContract = await new ethers.Contract(token_ContractAddres, tokenAbi, signer);
    let tokenApprovetxid = await mytokenContract.approve(logicStorageAddress, ethers.utils.parseUnits('5000000'));
    let approveRes = await tokenApprovetxid.wait();


    store.commit("UpdateIsTokenLogicStorage", true)

    return approveRes;
}

export async function allowance() {
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();

    let mytokenContract = await new ethers.Contract(token_ContractAddres, tokenAbi, signer);


    let checkAllowance = await mytokenContract.allowance(address, logicStorageAddress);


    if (Number(ethers.utils.formatEther(checkAllowance, 6)) > 0) {

        store.commit("UpdateIsTokenLogicStorage", true);
        return Number(ethers.utils.formatEther(checkAllowance, 6));
    }

    return 0;

}


//宝石授权
export async function gem_setApprovalForAll() {
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();

    let myContract = await new ethers.Contract(gem_ContractAddress, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(logicStorageAddress, true);


    if (tokenApprovetxid) {
        let approveRes = await tokenApprovetxid.wait();

        store.commit("UpdateIsGemNftLogicStorage", true)
        return approveRes;
    } else {
        return false
    }
    console.log('approveRes', approveRes);
    // 
}

export async function gem_isApprovedForAll() {
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();

    let myContract = await new ethers.Contract(gem_ContractAddress, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address, logicStorageAddress);


    if (isApproved) {
        store.commit("UpdateIsGemNftLogicStorage", true)
    }

    return isApproved;


}
//武器授权
export async function arm_setApprovalForAll() {
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();

    let myContract = await new ethers.Contract(arm_ContractAddress, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(logicStorageAddress, true);


    if (tokenApprovetxid) {
        let approveRes = await tokenApprovetxid.wait();

        store.commit("UpdateIsArmNftLogicStorage", true)
        return approveRes;
    } else {
        return false
    }
    console.log('approveRes', approveRes);
    // 
}

export async function arm_isApprovedForAll() {
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();

    let myContract = await new ethers.Contract(arm_ContractAddress, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address, logicStorageAddress);


    if (isApproved) {
        store.commit("UpdateIsArmNftLogicStorage", true)
    }

    return isApproved;


}

//人物授权
export async function role_setApprovalForAll() {
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();

    let myContract = await new ethers.Contract(role_ContractAddress, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(logicStorageAddress, true);


    if (tokenApprovetxid) {
        let approveRes = await tokenApprovetxid.wait();

        store.commit("UpdateIsRoleNftLogicStorage", true)
        return approveRes;
    } else {
        return false
    }
    console.log('approveRes', approveRes);
    // 
}

export async function role_isApprovedForAll() {
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();

    let myContract = await new ethers.Contract(role_ContractAddress, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address, logicStorageAddress);


    if (isApproved) {
        store.commit("UpdateIsRoleNftLogicStorage", true)
    }

    return isApproved;


}

//宝石销毁价格
export async function getDestroyPrice(){
    await start()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();

    let myContract = await new ethers.Contract(logicStorageAddress, storageAbi, signer);
    let price = await myContract.getDestroyPrice();


    if (price) {
        store.commit("UpdateDestroyPrice", price)
    }

    return price;

}