
import state from "./state";



const getters = {
  getXyjTokenBalance(state){
    return state.xyjTokenBalance?state.xyjTokenBalance:0;
  },
  getIsBossTokenAuth(state){
   
    return state.isBossTokenAuth?state.isBossTokenAuth:false;
  },
  getIsArmTokenAuth(state){
   
    return state.isArmTokenAuth?state.isArmTokenAuth:false;
  },
  getIsGemTokenAuth(state){
   
    return state.isGemTokenAuth?state.isGemTokenAuth:false;
  },
  getIsMarketTokenAuth(state){
   
    return state.isMarketTokenAuth?state.isMarketTokenAuth:false;
  },
  getIsTransferTokenAuth(state){
   
    return state.isTransferTokenAuth?state.isTransferTokenAuth:false;
  },
  getArmTransferNftAuth(state){
   
    return state.isArmTransferNftAuth?state.isArmTransferNftAuth:false;
  },
  getGemTransferNftAuth(state){
   
    return state.isGemTransferNftAuth?state.isGemTransferNftAuth:false;
  },
  getIsWalletConnect(state){
    console.log("是否存在state.isWalletConnect",state.isWalletConnect)
    return state.isWalletConnect?state.isWalletConnect:true;
   // return localStorage.getItem("isTokenAuth") ? localStorage.getItem("isTokenAuth"):state.isTokenAuth
  },
  getIsTokenAuth(state,isTokenAuth){
    return state.isTokenAuth ? state.isTokenAuth:false
   // return localStorage.getItem("isTokenAuth") ? localStorage.getItem("isTokenAuth"):state.isTokenAuth
  },
 
  getIsArmNftAuth(state){
    return state.isArmNftAuth ? state.isArmNftAuth:false
  },
  getIsRoleNftAuth(state){
    return state.isRoleNftAuth ? state.isRoleNftAuth:false
  },
  getIsGemNftAuth(state){
    console.log("获取宝石更新",state.isGemNftAuth)
    return state.isGemNftAuth ? state.isGemNftAuth:false
  },
  getIsMarketNftAuth(state){
    return state.isMarketNftAuth ? state.isMarketNftAuth:false
  },
  getIsMarketArmNftAuth(state){
    return state.isMarketArmNftAuth ? state.isMarketArmNftAuth:false
  },
  getIsMarketGemNftAuth(state){
    return state.isMarketGemNftAuth ? state.isMarketGemNftAuth:false
  },
  getIsLogicTransferNftAuth(state){
    return state.isLogicTransferNftAuth ? state.isLogicTransferNftAuth:false
  },
 
  getChoseRoleId(state){
    return state.choseRoleId ? state.choseRoleId:'0'
  },
  getRoleListData(state){
    return state.roleListData ? state.roleListData:''
  },
  getTotalMinePower(state){
    return state.totalMinePower ? state.totalMinePower:''
  },
  getErc20Address(state){
    
    return state.erc20Address ? state.erc20Address:''
  },
  //新版授权
  getTokenLogicStorage(state){
    return state.isTokenLogicStorage ? state.isTokenLogicStorage:false
  },
  getArmNftLogicStorage(state){
     
      return state.isArmNftLogicStorage ? state.isArmNftLogicStorage:false
  },
  getGemNftLogicStorage(state){
     
      return state.isGemNftLogicStorage ? state.isGemNftLogicStorage:false
  },
  getRoleNftLogicStorage(state){
    
      return state.isRoleNftLogicStorage ? state.isRoleNftLogicStorage:false
  },
  getChainId(state){
   
    return state.ChainId ? state.ChainId:0
  },
  getWaitCount(state){
    return state.waitCount ? state.waitCount:0
  },
  getWalletWait(state){
    return state.walletWait ? state.walletWait:false
  },
  getDestroyPrice(state){
    return state.destroyPrice ? state.destroyPrice:0
   
  },
}
export default getters