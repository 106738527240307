import {
    ethers
} from "ethers";

// let s344 = ethers.utils.toUtf8String("\xE4\xB8\xAD\xE5\x9B\xBD");


import vue from 'vue'
import store from "@/store";



console.log(ethers.utils.toUtf8CodePoints('中国'));

let erc20ContractAddress;
let tokenAbi;
async function start() {
    
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let res1= await signer.getChainId();
    let net_address='address'
      if (res1=='1337') {
        net_address='address'
      }else{
        net_address='address_bsc'
      }
   
    if (res1 == '1337') {
        net_address = 'address'
    } else {
        net_address = 'address_bsc'
    }

   
    const {
        default: Contract
    } = await import(`@/wallet/${net_address}/ERC20Token.erc20TokenAddress`);
    erc20ContractAddress = Contract.address;
    tokenAbi = Contract.abi;


};



export async function tokenAuto() {
    if (typeof window.ethereum !== 'undefined') {

        let currentAccount = null;
    
        console.log('MetaMask 已安装!');
    
        await window.ethereum.enable();
    
      
    
        let rs = await window.ethereum.request({
            method: 'eth_accounts'
        })
    
        if (!rs) {
            console.log("出错了")
        }
    
        // console.log('rs', rs);
        // console.log('isConnected', window.ethereum.isConnected());
    
        let res2 = await window.ethereum.request({
            method: 'eth_requestAccounts'
        });
    
        // console.log('res2', res2);
        // console.log(
        //     window.ethereum.isMetaMask
        // );
    
    
        window.ethereum.on('accountsChanged', (accounts) => {
    
            if (accounts.length === 0) {
    
                console.log('Please connect to MetaMask.');
            } else if (accounts[0] !== currentAccount) {
    
    
                console.log('accountsChanged', accounts);
    
            }
        });
    
        window.ethereum.on('chainChanged', (chainId) => {
            console.log('chainChanged', ethers.BigNumber.from(chainId).toNumber());
        });
    
        window.ethereum.on('connect', (connectInfo) => {
            console.log('connectInfo', connectInfo);
        });
    
        window.ethereum.on('disconnect', (error) => {
            console.log('disconnect', error);
        });

        window.ethereum.on('message', (message) => {
            console.log("钱包调用事件",message)
        });
    
    
    
        //await start();
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        let address = await signer.getAddress(); 
        let chainId = await signer.getChainId();
        store.commit("UpdateChainId",chainId);
        let net_address = 'address';
        
        if (chainId == '1337') {
            net_address = 'address'
        } else {
            net_address = 'address_bsc'
        }
        console.log("当前选择得链是",net_address)
    
        const {default: Contract} = await import(`@/wallet/${net_address}/ERC20Token.erc20TokenAddress`);
        erc20ContractAddress = Contract.address;
        tokenAbi = Contract.abi;
      
      
        let mytokenContract = await new ethers.Contract(erc20ContractAddress, tokenAbi, signer);
    
        let tokenAmount = await mytokenContract.balanceOf(address);
    
        store.commit("UpdateXyjTokenBalance", Math.floor(ethers.utils.formatEther(tokenAmount, 6) * 10000) / 10000);
       
        store.commit("UpdateErc20Address", address);
        store.commit("UpdateIsWalletConnect", true)
        
    } else {
    
        store.commit("UpdateIsWalletConnect", false);
        store.commit("UpdateErc20Address", '');
        // alert("当前还未安装钱包")
    }
}

export async function netChainId(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let chainId = await signer.getChainId();
    if(chainId){
        return chainId;
    }else{
        return false;
    }
    
}
export async function approve(address) {
    await start();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    if (!address) {
        address = await signer.getAddress();
    }

    let mytokenContract = await new ethers.Contract(erc20ContractAddress, tokenAbi, signer);
    let tokenApprovetxid = await mytokenContract.approve(address, ethers.utils.parseUnits('5000000'));
    let approveRes = await tokenApprovetxid.wait();


    store.commit("UpdateIsTokenAuth", true)

    return approveRes;
}

export async function allowance(ContractAddress) {
    await start();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();

    let mytokenContract = await new ethers.Contract(erc20ContractAddress, tokenAbi, signer);


    let checkAllowance = await mytokenContract.allowance(address, ContractAddress);


    if (Number(ethers.utils.formatEther(checkAllowance, 6)) > 0) {

        //store.commit("UpdateIsTokenAuth",true);
        return Number(ethers.utils.formatEther(checkAllowance, 6));
    }

    return 0;

}

export async function getBlbalance() {
    await start();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let mytokenContract = await new ethers.Contract(erc20ContractAddress, tokenAbi, signer);
    let address = await signer.getAddress();
    let tokenAmount = await mytokenContract.balanceOf(address);

    store.commit("UpdateXyjTokenBalance", ethers.utils.formatEther(tokenAmount, 6));
}