<template>
<!-- <div></div> -->
    <div class="waitCount" v-if="$store.getters.getWaitCount>0">
        {{$store.getters.getWaitCount}}
    </div>
    <Toast />
</template>

<script>
import { defineComponent,ref,watch,getCurrentInstance } from 'vue';
import Toast from 'primevue/toast';
import { useStore } from "vuex";
export default defineComponent({
    name:"waitCount",
    emits: ["getRoleListEvent"],
    props: [
        'title',
        'content'
    ],
    setup(props,{emit}) {
        const store = useStore();
        const { proxy } = getCurrentInstance(); 
        //console.log("tests");
        let getWaitCount=ref(store.getters.getWaitCount);
        console.log("当前等待数量",getWaitCount)
        watch(
            () => store.getters.getWaitCount,
            () => {
                    console.log("数量变化",store.getters.getWaitCount)
                 if(getWaitCount.value<store.getters.getWaitCount){
                    proxy.$toast.add(
                    {
                        severity:'warn',
                        summary:'交易进行中',
                        detail:'需要一定的时间进行交易确认，请等待',
                        life: 3000
                    });
                 }
              
            }
        );

        // watch(
        //     () => store.getters.getWalletWait,
        //     () => {
              
        //          if(store.getters.getWalletWait){
        //             proxy.$toast.add(
        //             {
        //                 severity:'warn',
        //                 summary:'唤醒钱包',
        //                 detail:'正在努力唤醒钱包',
        //                 life: 3000
        //             });
        //          }
              
        //     }
        // );

        // proxy.$toast.add(
        // {
        //     severity:'warn',
        //     summary:'交易进行中',
        //     detail:'需要一定的时间进行交易确认，请等待',
        //     life: 3000
        // });
      
      
  
        return {
            
        }
        
    },
    components : {
        Toast
    }
    
})
</script>
<style lang="scss" scoped>
.waitCount{
    position: fixed;
    border-radius: 100%;
    bottom: 20px;
    right:20px;
    background: #fff56d;
    color: #17212f;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1rem;
}
</style>
