import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import { createApp, reactive } from 'vue';
import router from './router';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import StyleClass from 'primevue/styleclass';
import Ripple from 'primevue/ripple';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import ToastService from 'primevue/toastservice';
import store from "./store"
import Web3 from 'web3';

import {
    ethers
} from "ethers";

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    next();
});

const app = createApp(App);

app.config.globalProperties.$appState = reactive({ theme: 'lara-light-indigo', darkTheme: false });
app.config.globalProperties.Web3 = Web3;
app.config.globalProperties.ethers=ethers;

app.config.globalProperties.tokenName="$XYJ";
app.config.globalProperties.MathPrice=(num)=>{
    return Math.floor(num * 10000) / 10000
    // if(!Number.isInteger(num))
    // {
    //     return Math.floor(num * 10000) / 10000
    // }else{
    //     return num;
    // }
 }
app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });

app.use(ToastService);
app.use(store).use(router);

app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.component('Button', Button);
app.component('Dropdown', Dropdown);

app.mount('#app');