


import {
    getRoleList
} from "../wallet/LogicRole";
import {netChainId} from "../wallet/tokenAuto";
const actions = {
    fetchRoleListData({ dispatch, commit }){
        let res=new Promise(function(resolve,reject){
            getRoleList().then(res => {
                console.log("action返回数组", res)
                let newRes = res.map((item, index, res) => {
                    let newArr = [];
                    newArr.arms = item.arms;
                    newArr.canMine = item.canMine;
                    newArr.gemstones = item.gemstones;
                    newArr.maxGems = item.maxGems;
                    newArr.minePower = item.minePower;
                    newArr.maxGems = item.maxGems;
                    newArr.role = item.role;
                    newArr.isShow = false;
                    //res.concat({"isShow":false});
                    return newArr;
                });
                let arrs = newRes.map((value, index, array) => {
					return value.minePower
				})
                let totalMinePower=arrs.reduce(( acc, cur ) => {
					return Number(acc) + Number(cur)
				}, 0);
               
                commit("UpdateTotalMinePower",totalMinePower);
                commit("UpdateRoleListData",newRes);
                resolve(newRes);
            })
        }).catch(error => {
            console.log("action获取rolelist失败")
        })
        return res;
    },
    fetchChainId({ dispatch, commit }){
        let res=new Promise(function(resolve,reject){
            netChainId().then(res => {
                console.log("action 获取链id",res)
                resolve(res);
            })
        }).catch(error => {
            console.log("action获取链id失败")
        })
        return res;
    }
}
export default actions;