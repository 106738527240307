<template>
    
    <div v-if="$store.getters.getErc20Address" class="authListBtn">
        <Button :label="Token_Tips" icon="iconfont icon-jiaose-" 
        class="mr-2" @click="ClickTokenAuth" v-if="!$store.getters.getTokenLogicStorage"
        :disabled="isTokenAuth==2"></Button>
       
        <Button :label="role_nftAuth_tips" icon="iconfont icon-jiaose-" 
        class="mr-2 mt-2" @click="ClickRoleNftAuth" v-if="!$store.getters.getRoleNftLogicStorage"
        :disabled="isRoleNftAuth==2"></Button>

        <Button :label="arm_nftAuth_tips" icon="iconfont icon-jiaose-" 
        class="mr-2 mt-2" @click="ClickArmNftAuth" v-if="!$store.getters.getArmNftLogicStorage"
        :disabled="isArmNftAuth==2"></Button>

        <Button :label="gem_nftAuth_tips" icon="iconfont icon-jiaose-" 
        class="mr-2 mt-2" @click="ClickGemNftAuth" v-if="!$store.getters.getGemNftLogicStorage"
        :disabled="isGemNftAuth==2"></Button>
    </div>
    <div v-else>
         <Button label="链接钱包" icon="iconfont icon-jiaose-" 
        class="mr-2 mt-2" @click="ClickConnect"></Button>
    </div>
</template>


<script>
import { defineComponent,ref,watch,computed,onMounted } from 'vue';

import { useStore } from 'vuex';
import *  as LogicStorginAuto from "../../wallet/LogicStorginAuto";


export default defineComponent({
    name:"AuthList",
    setup() {
        const store = useStore();
        // LogicStorginAuto.start();
        
        //判断token授权
        const isTokenAuth=ref(1);
        //
        const Token_Tips=ref("当前暂未授权ERC20合约,点击授权");
        //判断NFT授权情况
        const isRoleNftAuth=ref(1);
        const isArmNftAuth=ref(1);
        const isGemNftAuth=ref(1); 
        //button按钮文字
        let role_nftAuth_tips=ref("当前未授权角色合约");
        let arm_nftAuth_tips=ref("当前未授权武器合约");
        let gem_nftAuth_tips=ref("当前未授权宝石合约");

        //监听
        let UpdateIsTokenAuth=computed(()=>store.getters.isTokenAuth);

        const startApp=()=>{
        
            LogicStorginAuto.allowance().then(res=>{
                console.log("token合约返回授权全部调用结果",res)
                if(res<=0){
                    isTokenAuth.value=0;
                }
            })
            LogicStorginAuto.role_isApprovedForAll().then(res=>{
                console.log("角色合约返回授权全部调用结果",res)
                if(!res){
                    isRoleNftAuth.value=0;
                }
            })

            LogicStorginAuto.arm_isApprovedForAll().then(res=>{
                console.log("武器合约返回授权全部调用结果",res)
                if(!res){
                    isArmNftAuth.value=0;
                }
            })

            LogicStorginAuto.gem_isApprovedForAll().then(res=>{
                console.log("宝石合约返回授权全部调用结果",res)
                if(!res){
                    isGemNftAuth.value=0;
                }
            })
       
        }

        onMounted(() => {
            startApp()
            
        })
       
        
       watch(()=>store.getters.getErc20Address,()=>{
          startApp()
        })
        
        
       const ClickTokenAuth=()=>{
          Token_Tips.value="正在授权Token合约";
            
            console.log("当前token授权点击状态",isTokenAuth.value)
             if(isTokenAuth.value==0){
                 isTokenAuth.value=2;
                  
                 LogicStorginAuto.approve().then((res)=>{
                   isTokenAuth.value=1;
                   
                }).catch((res)=>{
                   isTokenAuth.value=0;
                    role_nftAuth_tips.value="当前暂未授权Token合约,点击授权";
                })
            }
        }
        // ClickTokenAuth()
       
        //角色点击授权
        const ClickRoleNftAuth=()=>{
           
            role_nftAuth_tips.value="正在授权宝石NFT合约";
           
            if(isRoleNftAuth.value==0){
                 isRoleNftAuth.value=2;
                LogicStorginAuto.role_setApprovalForAll().then((res)=>{
                   isRoleNftAuth.value=1;
                    window.location.reload();
                }).catch((res)=>{
                   isRoleNftAuth.value=0;
                    role_nftAuth_tips.value="当前暂未授权NFT合约,点击授权";
                })
            }
            //  let nftAuthAddress=nftAuth();
            
        }
        //武器角色授权
        const ClickArmNftAuth=()=>{
           
            arm_nftAuth_tips.value="正在授权武器NFT合约";
           
            if(isArmNftAuth.value==0){
                 isArmNftAuth.value=2;
                LogicStorginAuto.arm_setApprovalForAll().then((res)=>{
                   isArmNftAuth.value=1;
                    window.location.reload();
                }).catch((res)=>{
                   isArmNftAuth.value=0;
                    arm_nftAuth_tips.value="当前暂未授权武器NFT合约,点击授权";
                })
            }
            //  let nftAuthAddress=nftAuth();
            
        }

        //宝石角色授权
        const ClickGemNftAuth=()=>{
           
            gem_nftAuth_tips.value="正在授权宝石NFT合约";
           
            if(isGemNftAuth.value==0){
                 isGemNftAuth.value=2;
                LogicStorginAuto.gem_setApprovalForAll().then((res)=>{
                   isGemNftAuth.value=1;
                    window.location.reload();
                }).catch((res)=>{
                   isGemNftAuth.value=0;
                    gem_nftAuth_tips.value="当前暂未授权宝石NFT合约,点击授权";
                })
            }
            //  let nftAuthAddress=nftAuth();
            
        }
      
        //链接钱包
        const ClickConnect=(()=>{
            window.location.reload();
           
        })
        return{
            ClickConnect,
            isTokenAuth,
            isRoleNftAuth,
            isArmNftAuth,
            isGemNftAuth,
            role_nftAuth_tips,
            arm_nftAuth_tips,
            gem_nftAuth_tips,
            ClickTokenAuth,
            ClickRoleNftAuth,
            ClickArmNftAuth,
            ClickGemNftAuth,
            Token_Tips

        }
    },
    
    
})
</script>

<style lang="scss" scoped>
.authListBtn{
    button{
        display: block;
    }
}
</style>
