<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button layout-topbar-button"
		 @click="onMenuToggle" >
			<i class="pi pi-bars"></i>
		</button>
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			
		</router-link>
		<div>
		<selectRoleModal type='topMenu'/>
		</div>
		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top ">
			<li>
				
			
				<linkCharge />
			</li>
			<li>
				<ToWithdrawModal/>
			</li>
		
			<li class="flex align-items-center">
				<i class="iconfont icon-qianbao text-warning mr-2" />
				<div>
					<p class="mb-0">
						{{$store.getters.getXyjTokenBalance+' $XYJ'}}
					</p>
					<div class="tooltip">
						{{$store.getters.getErc20Address.slice(0,5)}}...{{$store.getters.getErc20Address.slice(-4)}}
				
						<span class="tooltiptext tooltip-left">{{$store.getters.getErc20Address}}</span>
					</div>
					
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { defineComponent,ref} from "vue";
import selectRoleModal from "./components/Modal/selectRoleModal.vue";
import ToWithdrawModal from "./components/Modal/toWithdraw.vue";
import {getReward,withdrawToken} from "./wallet/LogicBoss";
import linkCharge from "./components/TopSelect/linkCharge.vue";
import {
    ethers
} from "ethers";
export default defineComponent({
	setup() {
		let dropdownValue='';
		let dropdownValues = [
			{name: '一级武器', code: '0'},
			
		]
		// let rewar=ref('');
		// getReward().then(res=>{
		// 	console.log("奖励2223",res)
		// 	rewar.value=res[0].rewar;
			
		// })
		const ToWithdrawToken=()=>{
			console.log("发起提现")
			withdrawToken().then(res=>{
				
			})
		}
		return{
			
			dropdownValue,
			dropdownValues,
			ToWithdrawToken
		}
	},

	 components : {
		selectRoleModal,
		ToWithdrawModal,
		linkCharge
	 },
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return 'images/logo_full.png'
			//return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
})
</script>
<style lang="scss" scoped>
.layout-topbar-menu{
	li{
		position: relative;
	}
}
</style>