<template >
    <template v-if="$store.getters.getErc20Address">
        
        <template v-if="choseNow&&type=='topMenu'">
            <Button :label="$store.getters.getRoleListData[$store.getters.getChoseRoleId].role.username" icon="iconfont icon-jiaose-" 
            class="mr-2"
            @click="openModal"></Button>
            <Button :label="$store.getters.getRoleListData[$store.getters.getChoseRoleId].arms.length+'/10'" icon="iconfont icon-jian-tianchong" 
            class="mr-2 p-button-raised p-button-secondary"></Button>
            <Button :label="$store.getters.getRoleListData[$store.getters.getChoseRoleId].gemstones.length+'/'+$store.getters.getRoleListData[$store.getters.getChoseRoleId].maxGems.toNumber()" icon="iconfont icon-zhubaopeishi" 
            class="mr-2 p-button-raised p-button-secondary"></Button>
          
            <!-- <p class="mb-1">
                战力：<span class="text-warning">{{$store.getters.getTotalMinePower}}</span>
            </p> -->
            <Button :label="$store.getters.getRoleListData[$store.getters.getChoseRoleId].role.ps.toNumber()?$store.getters.getRoleListData[$store.getters.getChoseRoleId].role.ps.toNumber():'0'" icon="iconfont icon-nengliang" 
                class="mr-2 p-button-raised p-button-secondary"></Button>
                <Button :label="'战力'+$store.getters.getRoleListData[$store.getters.getChoseRoleId].minePower" 
                class="mr-2 p-button-raised p-button-secondary"></Button>
                <Button :label="'等级'+$store.getters.getRoleListData[$store.getters.getChoseRoleId].role.level" 
                class="mr-2 p-button-raised p-button-secondary"></Button>
            <!-- <p class="mb-1">
                战力：<span class="text-warning">{{$store.getters.getRoleListData[$store.getters.getChoseRoleId].minePower}}</span>
            </p>
            <p class="mb-1">
                当前等级：<span class="text-warning">{{$store.getters.getRoleListData[$store.getters.getChoseRoleId].role.level}}</span>
            </p> -->
                
           
        </template>
        <template v-else-if="choseNow&&type=='mapPage'">
            <Button :label="$store.getters.getRoleListData[$store.getters.getChoseRoleId].role.username" icon="iconfont icon-jiaose-" class="mr-2"
            @click="openModal"></Button>
        </template>
        <template v-else>
         <Button label="创建角色" icon="iconfont icon-jiaose-" class="mr-2"
            @click="createRole"></Button>
        </template>
            
    </template>
   
    <Dialog header="选择角色" v-model:visible="display" 
    
            :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
            :modal="true" @update=update>
        <!-- <h5 class="mb-4 text-center">请选择一个角色</h5> -->
        <div class="text-center">
             <div class="ListBoxShow">
                     <div class="listBox" v-for="item,index in roleList" 
                        @click="choseRole(item,index)"
                        :class="isChoseRole==index?'active':''"
                        v-if="roleList"
                        :style="{backgroundImage:'url('+require('@/assets/img/role/'+item.role.number.toNumber()+'.jpg')+')'}">
                            <p>{{item.role.username}}</p>
                            <p>等级：{{item.role.rank}}</p>
                        </div>
                        <hr>
               
                </div>
                 <Button label="确定" @click="comfirm" 
                    class="mt-2 w_10 "/>
        </div>
      
    </Dialog>

    
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import {
		getRoleList
	} from "../../wallet/LogicRole";
import {
		useRouter
	} from "vue-router";
export default defineComponent({
    name:"selectRoleModal",
    emits: [],
    props: [
       
        'type'
    ],
    setup(props,{emit}) {
        const store = useStore();
        let roleList =ref();
        let display=ref(props.showNameModal);
        let choseNow=ref();
        const router = useRouter();
        let isChoseRole=ref(0);
        let totalMinePower=ref(0);
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
       
        const getRoleListEvent = () => {
            roleList.value = [];
            store.dispatch('fetchRoleListData').then((res) => {
                console.log("actions挑取成功",res)
                if(res){
                    choseNow.value = res[0];
                    roleList.value = res;
                }
              
            })
				
		}
		getRoleListEvent();
            
        const openModal=()=>{
            
             display.value=true
        }

        const choseRole=(item,index)=>{
             choseNow.value = item;
            
             isChoseRole.value=index;
             store.commit("UpdateChoseRoleId",index)
            //display.value=false
        }

        const comfirm=()=>{
               display.value=false;
                // LogicMarket.sellArms((roleInfo.value.itemId).toNumber(),psAmount.value).then(res=>{
                //    emit("sellArmEvent");
                // })
         
            return 
        }
        watch(()=>store.getters.getErc20Address,()=>{
          getRoleListEvent();
        })
        
        watch(()=>store.getters.getChoseRoleId,()=>{
          getRoleListEvent();
        })

        const createRole=()=>{
            router.push("/role");
        }

        

        return {
            display,
            openModal,
            roleList,
            choseRole,
            choseNow,
            createRole,
            isChoseRole,
            comfirm,
            totalMinePower
        }
        
    },
    components : {
        Dialog,
    }
    
})
</script>
<style lang="scss" scoped>
.ListBoxShow{
	
		width:100%;
		max-height: 300px;
      
        overflow: auto;
		i{
			text-align: right;
			display: block;
			width: 100%;
			margin-right: 20px;
			margin-bottom: 20px;
		}
        
		.listBtn{
			width: 100%;
			display: flex;
			.p-button{
				flex:1;
				border-radius: $radisIconbtn;
			}
		}
		.listBox{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 10px;
			margin-bottom: 10px;
			border-radius: $radisIconbtn;
				background: $themeColor;
				
			object-fit: cover;
			background-size: cover;
			background-position-y: center;
			margin: 10px 0;
            border: 1px solid rgba(0,0,0,0.7);;
			&.gemList{
				color: #212529;
			}
			p{
				margin: 0;
			}
            &:hover{
                border-color:$themeColor;
                cursor: pointer;
            }
            &.active{
                 border-color:$themeColor;
            }
		}
	}
</style>