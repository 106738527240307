<template>

	<div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
       
        <div class="layout-sidebar" @click="onSidebarClick">
             
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>
        
        <!-- <walletTip v-if="!$store.getters.getIsWalletConnect"/> -->

		
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>

        <waitCount />
        
        
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';

import {defineComponent,store,computed} from "vue";

import walletTip from './components/Modal/walletTip.vue';
import waitCount from './components/Global/waitCount.vue';

import {useRouter} from "vue-router";
import { useStore } from 'vuex';


 export default defineComponent({
    setup() {
        const router=useRouter();
        
    },
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu : [
                {
                    
                    items: [
                        {
                            label: 'YOONGHUMING', class:'chose mb-4 mt-0',
                            icon: 'iconfont icon-ren',
                            
                        },
                        {
                            label: 'Dashboard',class:"mb-4", 
                            icon: 'iconfont icon-shouye', to: '/'
                        },
                         {
                            label: '开始游戏', class:'chose', badge: "开始挣钱"
                        },
                        {label: '角色', icon: 'iconfont icon-jiaose-', to: '/role'},
                        {label: '武器', icon: 'iconfont icon-jian-tianchong', to: '/arms'},
                        {label: '宝石', icon: 'iconfont icon-zhubaopeishi ', to: '/gem'},
                        {label: '挑战', icon: 'iconfont icon-ditu ', to: '/map'},
                        //  {label: '战斗', icon: 'iconfont icon-jian-tianchong ', to: '/battle/entry'},
                         {
                            label: '市场', class:'chose', badge: "买卖你的NFT"
                        },
                        {label: '人物', icon: 'iconfont icon-gouwucheman ', to: '/market/role'},
                        {label: '武器', icon: 'iconfont icon-gouwucheman ', to: '/market/arm'},
                        {label: '宝石', icon: 'iconfont icon-gouwucheman ', to: '/market/gem'},
                       {
                            label: '获取 $XYJ', class:'chose', 
                        },
                        {label: 'PancakeSwap', icon: 'iconfont icon-busd ', 
                        href: 'https://pancake.kiemtienonline360.com/#/swap',
                        url:'https://pancake.kiemtienonline360.com/#/swap',
                        target:'_blank'},
                        
 {
                            label: '帮助', class:'chose', badge: "玩法教程"
                        },
                        {label: '白皮书', icon: 'iconfont icon-zhibiaobaipishu ', to: '/formlayout'},
                        {
                            label: '社交', class:'chose', badge: "其他友情链接"
                        },
                        {label: 'TikTok', icon: 'iconfont icon-tiktok', to: '/formlayout'},
                        {label: 'FaceBook', icon: 'iconfont icon-facebookfacebook52', to: '/formlayout'},
                        {label: 'Twitter', icon: 'iconfont icon-tuitetwitter43', to: '/formlayout'},
                         {label: 'Ins', icon: 'iconfont icon-instagram-fill', to: '/formlayout'},
                          {
                            label: '关于', class:'chose', badge: "隐私政策"
                        },
                    ]
                },
				
            ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
          
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        containerClass() {
            return [
            'layout-wrapper', {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                    'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                    'layout-mobile-sidebar-active': this.mobileMenuActive,
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': this.$primevue.config.ripple === false,
                    'layout-theme-light': this.$appState.theme.startsWith('saga')
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        "walletTip":walletTip,
        'AppFooter': AppFooter,
        waitCount,
        
        
    }
 });
</script>

<style lang="scss">
@import './App.scss';
</style>
