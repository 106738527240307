import {
  ethers
} from "ethers";

import store from "@/store";



let ContractAddress;
let Abi;
async function start(){
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();
let res1= await signer.getChainId();
let net_address='address'
  if (res1=='1337') {
    net_address='address'
  }else{
    net_address='address_bsc'
  }

 
  const { default: Contract } = await import(`@/wallet/${net_address}/LogicBoss.logicBossAddress.json`);
  ContractAddress = Contract.address;
  Abi = Contract.abi;


};

import { approve, allowance} from "./tokenAuto";

export async function isAllowance() {
await start();
  let isAllowance=await allowance(ContractAddress);
 
  return isAllowance;
}
export async function toApprove() {
  let isApprove=await approve(ContractAddress,Abi);
  return isApprove;
}

export async function getReward(){
await start();
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let address= await signer.getAddress();
let res = await myContract.getReward();

if(res){
    
    return res;
}else{
  return false
}
}
export async function getWithdrawalCost(){
await start();
// const provider = new ethers.providers.Web3Provider(window.ethereum)
// const signer = provider.getSigner();

// let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

// let address= await signer.getAddress();
// let res = await myContract.getWithdrawalCost();

// if(res){
    
//     return res;
// }else{
//   return false
// }
return false
}
export async function withdrawToken(){
await start();
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let address= await signer.getAddress();
let res = await myContract.withdrawToken();
if(res.wait()){
  await res.wait();
 
}
if(res){
    
    return res;
}else{
  return false
}
}

export async function rps(roleid,psNum) {
await start();
const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
 
  let address= await signer.getAddress();
  let res = await myContract.rps(roleid,psNum);
  store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)+1);
  if(res.wait()){
    await res.wait();
   
  }
  if(res){
      
      return res;
  }else{
    return false
  }
}


export  async function gemMaintenance(roleid,day) {
await start();
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let address= await signer.getAddress();
let res = await myContract.gemMaintenance(roleid,day);
store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)+1);
if(res.wait()){
  await res.wait();
 
}
if(res){
    
    return res;
}else{
   return false
}
}

export  async function getMapList(roleid,day) {
  console.log("调用钱包获取所有地图列表",roleid);
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

  let address= await signer.getAddress();
 
  let res = await myContract.getMapList(roleid);
  
  if(res){
      
      return res;
  }else{
     return false
  }
}

export  async function challengeBoss(bossLevel,roleid) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

  let address= await signer.getAddress();
  let max=9999;
  let min=1000;
  let num=Math.floor(Math.random()*(max-min))+min;
  let res = await myContract.challengeBoss(bossLevel,roleid,num);
  let res1=[];
  if(res.wait()){
    res=await res.wait();

    const iface = new ethers.utils.Interface(Abi);
    for (let i = 0; i < res.events.length; i++) {
        if (res.events[i].event == 'challengeBossResult') {
            const pasel = await iface.parseLog({
                data: res.events[i].data,
                topics: res.events[i].topics
            });
           
            res1.push({"addr":pasel.args['addr']});
            res1.push({"rollNum":pasel.args['rollNum']})
            res1.push({"need":pasel.args['need']})
            res1.push({"epNum":pasel.args['epNum']})
            res1.push({"level":pasel.args['level']})
            res1.push({"reward":pasel.args['reward']})
            res1.push({"txid":res.transactionHash})

            
        }
    }
    return res1
  }else{
    return false
  }
 
}



