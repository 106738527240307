<template>
    <Button label="买$XYJ" 
    class="mr-2 p-button-raised p-button-secondary"
    @click.stop="isShow=!isShow"></Button>
    <div v-if="isShow" class="charageBtnGroup" @click="openLink()">
        <div class="charageList">
            <img src="@/assets/img/pancake_Icon.png"/>
            <div>
                <h6>
                    PancakeSwap Swap
                </h6>
                <p>
                    在PancakeSwap上购买$XYJ
                </p>
            </div>
        </div>
    </div>
    
</template>
<style lang="scss" scoped>
.charageBtnGroup{
    background: var(--surface-card);
    padding: 10px;
    position: absolute;
    right: 0;
    top:4rem;
    
    white-space: nowrap;
    border-radius:$radisIconbtn;
    .charageList{
        display:flex;
        align-items: center;
        padding:10px;
            cursor: pointer;
            border-radius:$radisIconbtn;
        img{
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-right:10px;
        }
        &:hover{
            background:$bg-color;
        }
    }
}

</style>

<script>
import { defineComponent,ref,store,computed} from 'vue';
export default defineComponent({
    name:"linkCharge",
    setup() {
        let isShow=ref(false);
        const openLink=()=>{
            window.open("https://pancake.kiemtienonline360.com/#/swap")
        }

        window.addEventListener("click",()=>{
            if(isShow.value){
                isShow.value=false;
            }
        });
        return{
            isShow,
            openLink
        }
    }
})
</script>
