import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './pages/Dashboard.vue';
import store from "@/store";
import {tokenAuto} from "./wallet/tokenAuto";
import *  as LogicStorginAuto from "./wallet/LogicStorginAuto";
const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/role',
        name: 'role',
        component: () => import('./pages/RolePage.vue')
    },
    {
        path: '/role/upgrade/:id',
        name: 'role_upgrade',
        component: () => import('./pages/role/UpgradePage.vue')
    },
    {
        path: '/arms',
        name: 'arms',
        component: () => import('./pages/ArmsPage.vue')
    },
    {
        path: '/gem',
        name: 'gem',
        component: () => import('./pages/GemPage.vue')
    },
    {
        path: '/map',
        name: 'map',
        component: () => import('./pages/MapPage.vue')
    },
    {
        path: '/market/role',
        name: 'market_map',
        component: () => import('./pages/market/RolePage.vue')
    },
    {
        path: '/market/arm',
        name: 'market_arm',
        component: () => import('./pages/market/ArmPage.vue')
    },
    {
        path: '/market/gem',
        name: 'market_gem',
        component: () => import('./pages/market/GemPage.vue')
    },
    {
        path: '/battle/entry',
        name: 'battle_entry',
        component: () => import('./pages/battle/EntryPage.vue')
    },
    {
        path: '/battle/make',
        name: 'battle_make',
        component: () => import('./pages/battle/MakePage.vue')
    },
   
   
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    
    //检查地址授权
    //allowance();
    // allowance().then(res=>{
    //     console.log("调用授权情况",res);
    //     if(!res){
    //         store.commit("UpdateIsTokenAuth",false)
    //         router.push("/");
    //     }
    // });
    tokenAuto();
   
    LogicStorginAuto.allowance().then(res=>{
       
        if(res<=0){
            console.log("挑战")
            router.push("/");
        }
    })
    LogicStorginAuto.role_isApprovedForAll().then(res=>{
        console.log("路由返回2",res)
        if(!res){
            router.push("/");
        }
    })

    LogicStorginAuto.arm_isApprovedForAll().then(res=>{
        console.log("路由返回3",res)
        if(!res){
            router.push("/");
        }
    })

    LogicStorginAuto.gem_isApprovedForAll().then(res=>{
        console.log("路由返回4",res)
        if(!res){
            router.push("/");
        }
    })

    LogicStorginAuto.getDestroyPrice();

    
    
    next()
})
export default router;
