<template>
	<Button :label="'领取 '+ MathPrice(Number(ethers.utils.formatEther(rewar))) +' $XYJ'" icon="iconfont icon-feiyong" class="mr-2" 
    @click="ToWithdrawToken"></Button>
    <Dialog header="领取奖励" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
       <div class="text-center">
       <h5 class="text-center">
           你可领取 <span class="text-success ml-2">
               
                {{MathPrice(Number(ethers.utils.formatEther(rewar)))}}
            </span> $XYJ
            
       </h5>
       <h5>
           你将需要支付
           <span class="text-danger ml-2">
                {{MathPrice(Number(ethers.utils.formatEther(costFee)))}}
            </span>  $XYJ 手续费（30%）
       </h5>
       <p class="text-center"> 手续费每天递减 2%</p>
       </div>
      <hr>
      <div class="text-center">
        <Button label="立即领取" @click="comfirm" icon="pi pi-check" 
            v-if="rewar>0"/>
             <Button label="暂无可领取奖励,关闭" @click="close"  
            class="p-button-secondary" v-else />
      </div>
    </Dialog>

   
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import {getReward,withdrawToken,getWithdrawalCost} from "../../wallet/LogicBoss";
export default defineComponent({
    name:"ToWithdrawModal",
    emits: ["tarnferGemEvent"],
    props: [
        'roleInfo'
    ],
    setup(props,{emit}) {
        const store = useStore();
        const roleInfo=ref(props.roleInfo)
        let display=ref(props.showNameModal);
        let address=ref("");
       let rewar=ref(0);
       let costFee=ref(0);
      
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })

       
        const close=()=>{
            display.value=false;
        }
        const ModalOpen=()=>{
             console.log("传过来的角色信息",roleInfo.value)
             display.value=true
        }
        const comfirm=()=>{
            display.value=false;
          	withdrawToken().then(res=>{
				
			})
         
            return 
        }
		const getRewardFn=()=>{
            getReward().then(res=>{
                console.log("获取当前奖励",res)
                rewar.value=res[0].rewar;
                costFee.value=res[1];
            })
        }
        getRewardFn();
            watch(()=>store.getters.getXyjTokenBalance,()=>{
           
            getRewardFn()
        })

        // getWithdrawalCost().then(res=>{
        //     console.log("当前体现手续费",res)
        // })
      const ToWithdrawToken=()=>{
			// console.log("发起提现")
             display.value=true
			
		}
       

        return {
            ModalOpen,
            address,
            ToWithdrawToken,
            comfirm,
            display,
            rewar,
            close,
            costFee
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
