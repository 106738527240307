<template>
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true">
        <p class="line-height-3 m-0">
            当前钱包链接有问题，请先解锁钱包或或检查是否安装钱包
        </p>
        <template #footer>
            <Button label="我已操作,立即刷新" @click="close" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>
</template>

<script>
import { defineComponent,ref } from 'vue';
import Dialog from 'primevue/dialog'; 
export default defineComponent({
    name:"walletTip",
    setup() {
        let display=ref(true);
        //console.log("tests");

        const close=()=>{
            window.location.reload();
            display.value=false;
            return 
        }
        return {
            close,
            display
        }
        
    },
    components : {
        Dialog
    }
    
})
</script>
